// ------------------
//  ANIMATIONS
// ------------------

$dTransition: all 0.2s ease;
$infobarTransition: all 0.3s ease;
$btnTransition: all 0.3s ease;

@keyframes inboxInactive {
  0% {
    color: $blue;
    font-family: $primary;
    font-size: 14px;
  }

  100% {
    color: #020202;
    font-family: $primaryBold;
    font-size: 28px;
  }
}

@keyframes inboxActive {
  0% {
    color: #020202;
    font-family: $primaryBold;
    font-size: 28px;
  }

  100% {
    color: $blue;
    font-family: $primary;
    font-size: 14px;
  }
}

@keyframes applelogo {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes phoneSignal {
  0% {
    background: $noSignal;
  }

  49% {
    background: $noSignal;
  }

  50% {
    background: $solidWhite;
  }

  100% {
    background: $solidWhite;
  }
}

@keyframes phoneSignalInverted {
  0% {
    background: $noSignalInverted;
  }

  49% {
    background: $noSignalInverted;
  }

  50% {
    background: $solidBlack;
  }

  100% {
    background: $solidBlack;
  }
}

@keyframes wifiSignal {
  0% {
    border-color: $noSignal;
  }

  49% {
    border-color: $noSignal;
  }

  50% {
    border-color: $solidWhite;
  }

  100% {
    border-color: $solidWhite;
  }
}

@keyframes wifiSignalInverted {
  0% {
    border-color: $noSignalInverted;
  }

  49% {
    border-color: $noSignalInverted;
  }

  50% {
    border-color: $solidBlack;
  }

  100% {
    border-color: $solidBlack;
  }
}

@keyframes bar1 {
  0% {
    height: 2px;
  }

  50% {
    height: 6px;
  }

  100% {
    height: 2px;
  }
}

@keyframes bar2 {
  0% {
    height: 2px;
  }

  50% {
    height: 10px;
  }

  100% {
    height: 2px;
  }
}

@keyframes bar3 {
  0% {
    height: 2px;
  }

  50% {
    height: 7px;
  }

  100% {
    height: 2px;
  }
}

@keyframes bar4 {
  0% {
    height: 2px;
  }

  50% {
    height: 8px;
  }

  100% {
    height: 2px;
  }
}
