@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

.container {
  z-index: 998;
  display: flex;
  width: 100%;
  height: 20px;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  opacity: 0;
  transition: all 1s ease 1s;
}

.time,
.info {
  display: flex;
  width: 70px;
  height: 20px;
  align-items: flex-end;
}

.time {
  z-index: 9999;
  justify-content: center;
  color: $solidWhite;
  font-family: $primary;
  font-size: 12px;
  line-height: 13px;
  text-align: center;
  transition: $infobarTransition;

  &.invert {
    color: $solidBlack;
  }
}

.info {
  padding-bottom: 3px;
}

.display {
  opacity: 1;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
