@import '../../sharedStyles/variables';

$number: #ff1204;
$text: #2f7c98;

// ------------------
//  CONTENT
// ------------------

.container {
  z-index: 100;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-content: center;
  padding: 30px 15px 15px;
  background: $solidWhite;
  font-size: 12px;
  // text-align: center;
}

.imgContainer {
  overflow: hidden;
  width: 150px;
  height: 150px;
  border: 6px solid #e8e8e8;
  margin: 0 auto;
  margin-bottom: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 26px;
}

.img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.title {
  font-size: 14px;
  text-align: center;
}

.name {
  margin-bottom: 5px;
  color: $text;
}

.position {
  color: $text;
}

.header,
.body {
  line-height: 1.2;
}

.header {
  font-size: 12px;
}

.body {
  font-size: 14px;

  &:not(:first-of-type) {
    margin-top: 10px;
  }
}

.break {
  margin: 12px 0;
  border-color: $text;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
