@import '../../sharedStyles/variables';

$number: #ff1204;

// ------------------
//  CONTENT
// ------------------

.container {
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 34px;
  height: 50vh;
  flex-direction: column;
  padding: 0;
  background: none;
  border-radius: 0;
  transition: all 1.5s ease-in-out;
}

.container,
.plug,
.head,
.neck,
.cord {
  margin: 0 auto;
}

.head,
.neck,
.cord {
  box-shadow: inset 7px 0 15px -8px rgba(0, 0, 0, 0.75), inset -7px 0 20px -8px rgba(0, 0, 0, 0.75);
}

.plug {
  width: 30px;
  height: 28px;
  padding-top: 3px;
  background-color: #bebfc1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tip {
  display: flex;
  width: 26px;
  height: 14px;
  justify-content: center;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 3px;
}

.gold {
  display: inline-block;
  width: 2px;
  height: 8px;
  margin: 3px 1px 0 0;
  background-color: #89805f;
  border-radius: 2px;
  float: left;
}

.head {
  width: 34px;
  height: 52px;
  background-color: #fff;
  border-radius: 2px;
}

.neck {
  width: 18px;
  height: 44px;
  background-color: #fff;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.cord {
  width: 12px;
  height: auto;
  flex-grow: 1;
  background-color: #fff;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
