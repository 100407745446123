@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

.bar {
  width: 3px;
  height: 4px;
  background: $solidWhite;
  border-radius: 2px;
  transition: $infobarTransition;

  &:not(:first-of-type) {
    margin-left: 1px;
  }

  &:nth-child(2) {
    height: 6px;
  }

  &:nth-child(3) {
    height: 8px;
  }

  &:nth-child(4) {
    height: 10px;
    animation: phoneSignal 25s infinite;
  }
}

.container {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-left: 5px;

  &.invert .bar {
    background: $solidBlack;

    &:nth-child(4) {
      animation-name: phoneSignalInverted;
    }
  }
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
