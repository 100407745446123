@import '../../../../sharedStyles/variables';

$accent: #b1b1b5;

// ------------------
//  CONTENT
// ------------------

.container {
  // padding: 0 0 0 22px;
}

.unread {
  position: absolute;
  width: 9px;
  height: 9px;
  margin-left: -14px;
  background: #157efc;
  border-radius: 50%;
}

.wrapper {
  width: 100%;
  min-height: 75px;
  padding: 6px 14px 6px 22px;
  border: none;
  border-bottom: 1px solid $accent;
  background: #efefef;
  border-radius: 0;

  &.active {
    background: #e6e5eb;
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sender {
  font-family: $primaryBold;
  font-size: 14px;
}

.date {
  position: relative;
  margin-right: 14px;
  font-size: 12px;

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: calc(100% + 6px);
    width: 7px;
    height: 7px;
    border-right: 1px solid $accent;
    border-bottom: 1px solid $accent;
    content: '';
    transform: translateY(-50%) rotate(-45deg);
  }
}

.title,
.body {
  text-align: left;
}

.title {
  font-size: 12px;
}

.body {
  font-size: 12px;
  line-height: 1.4;
}

.date,
.body {
  color: $accent;
}

.details {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 14px;
  background: $solidWhite;
  transform: translateX(100%);
  transition: $dTransition;

  &Title {
    display: flex;
    height: auto;
    min-height: 60px;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid $accent;
    text-align: left;
  }

  &.display {
    box-shadow: 10px 0 24px 6px rgba(0, 0, 0, 0.75);
    transform: translateX(25%);
  }
}

.detailsHeader {
  display: flex;
  min-height: 58px;
  align-items: center;
  padding: 0 10px 0 0;
  border-bottom: 1px solid $accent;
}

.info {
  height: auto;
  font-size: 12px;
  text-align: left;

  &Title {
    font-family: $primaryBold;
    font-size: 14px;
  }

  &Date {
    margin-top: 6px;
    font-size: 12px;
    line-height: 12px;
  }
}

.bold {
  font-family: $primaryBold;
}

.senderIcon {
  display: flex;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background: #8a8e97;
  border-radius: 50%;
  color: $solidWhite;
  font-size: 12px;
}

.lighten {
  color: $accent;
}

.detailsBody {
  padding-top: 10px;
}

.message {
  padding-top: 8px;
  font-size: 13px;
  text-align: left;

  &:not(:first-of-type) {
    max-width: 260px;
    margin-top: 12px;
    letter-spacing: 0.2px;
  }
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
