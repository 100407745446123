@import '../../../../sharedStyles/variables';

$artist: #b1b1b3;
$border: #ededee;

// ------------------
//  CONTENT
// ------------------

.container,
.nowPlaying {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 44px;
  padding: 2px 0 0 4px;
  font-size: 11px;
  text-align: left;
}

.container {
  margin-bottom: 2px;
  background-color: $solidWhite;
  border-radius: 4px;
  transition: $dTransition;

  &:hover {
    background-color: darken($solidWhite, 5%);
  }
}

.album {
  position: relative;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.shadow {
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.85);
}

.image {
  overflow: hidden;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  border-radius: 4px;
}

.info {
  flex-grow: 1;
  padding: 3px 4px 4px 0;
  border-bottom: 1px solid $border;
  margin-left: 14px;
}

.title,
.artist {
  font-family: $fontLight;
}

.title {
  font-size: 14px;
  letter-spacing: 0.2px;
}

.artist {
  margin-top: 2px;
  color: $artist;
  letter-spacing: -0.4px;
}

.nowPlaying {
  min-height: 54px;
  align-items: center;
  padding: 0 14px 0 20px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  background-color: #f9f9f9;

  & .info {
    display: flex;
    align-items: center;
    border-bottom: none;
  }
}

.playBtn,
.nextBtn {
  width: 30px;
  height: 30px;
  padding: 0;
  background: none;
  border-radius: 50%;
  transition: $dTransition;

  &:not(.disabled):active {
    background: darken($white, 10%);
    transform: scale(0.8);
  }
}

.play,
.next {
  width: 0;
  height: 0;
  border-radius: 1px;
}

.play,
.pause {
  margin: 0 auto;
}

.playBtn {
  margin-left: auto;
}

.play {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 14px solid $solidBlack;
}

.pause {
  position: relative;
  display: flex;
  width: 10px;
  height: 16px;
  justify-content: space-between;

  &::before,
  &::after {
    display: block;
    width: 4px;
    height: 100%;
    background-color: $solidBlack;
    border-radius: 1px;
    content: '';
  }
}

.nextBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.next {
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 11px solid $solidBlack;
}

.disabled .next {
  border-left-color: #bbb;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba($solidBlack, 0.3);
  opacity: 1;
}

.bars {
  display: flex;
  width: 14px;
  height: 12px;
  align-items: flex-end;
  justify-content: space-between;
}

.bar {
  width: 2px;
  height: 2px;
  background: $white;
  transition: all 1s ease;

  &.paused {
    animation-iteration-count: 1 !important;
  }
}

.on {
  .bar1 {
    animation: bar1 0.4s infinite;
  }

  .bar2 {
    animation: bar2 0.6s infinite;
  }

  .bar3 {
    animation: bar3 0.3s infinite;
  }

  .bar4 {
    animation: bar4 0.5s infinite;
  }
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
