@import '../../../../sharedStyles/variables';

$accent: #b1b1b5;
$purple: #5d58ca;

// ------------------
//  CONTENT
// ------------------

.container {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: $solidWhite;
  border-radius: 8px;
  transform: translateY(100%);
  transition: $dTransition;

  &.active {
    max-height: calc(100% - 5.5%);
    transform: translateY(5.8%);
  }
}

.options {
  display: flex;
  height: auto;
  min-height: 48px;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  border-bottom: 1px solid $accent;
  background: #f9f9f9;
}

.bold {
  font-family: $primaryBold;
}

.title {
  position: absolute;
  z-index: 1;
  width: calc(100% - 28px);
  font-family: $primaryBold;
  font-size: 14px;
  letter-spacing: 0.3px;
  pointer-events: none;
  text-align: center;
}

.actionBtn {
  z-index: 2;
  padding: 0;
  background: none;
  border-radius: 0;
  color: $blue;
  font-size: 14px;
  line-height: 12px;
}

.header {
  &Block {
    display: flex;
    min-height: 38px;
    align-items: center;
    padding-left: 14px;
    border-bottom: 1px solid $accent;
    font-size: 12px;
  }
}

.lighten {
  color: $accent;
}

.blue {
  color: $blue;
}

.to {
  margin-left: 4px;
  color: $blue;
}

.subject {
  margin-left: 4px;
}

.form {
  // easy way to plop the elements on top of each other and
  // have them both sized based on the tallest one's height
  display: grid;

  // Identical styling required!!
  &::after {
    width: 100%;
    height: auto;
    padding: 14px;
    // Note the weird space! Needed to prevent jumpy behavior
    content: attr(data-replicated-value) ' ';
    font: inherit;
    font-size: 12px;
    grid-area: 1 / 1 / 2 / 2;
    transition: $dTransition;

    // Hidden from view, clicks, and screen readers
    visibility: hidden;

    // This is how textarea text behaves
    white-space: pre-wrap;
  }
}

// Identical styling required!!
.textarea {
  // Firefox shows scrollbar on growth, you can hide like this.
  overflow: hidden;
  width: 100%;
  height: auto;
  padding: 14px;
  font: inherit;
  font-size: 12px;
  // Place on top of each other
  grid-area: 1 / 1 / 2 / 2;
  // You could leave this, but after a user resizes, then it ruins the auto sizing
  resize: none;
  transition: $dTransition;
}

.label {
  min-width: 40px;
  transition: $dTransition;
}

.input {
  width: 100%;
  margin-right: 20px;
  font-size: 12px;
}

.footer {
  padding: 0 20px 0 4px;
  border-left: 1px solid $purple;
  margin-left: 14px;
  color: $purple;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 1.3;
}

.message {
  margin-top: 20px;
  color: $purple;
}

.error {
  & .label {
    color: red;
  }

  & .textarea {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: red;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: red;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: red;
    }
  }
}

.content {
  flex-grow: 1;
  overflow-y: auto;
}

.emailSignature {
  padding-left: 14px;
  font-size: 12px;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
