@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

.container {
  position: relative;
  z-index: 1;
  display: flex;
  width: 342px;
  height: 694px;
  background: $solidBlack;
  border-radius: 54px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.75);
}

.powerBtn {
  position: absolute;
  top: 20%;
  right: 0;
  bottom: 0;
  left: 100%;
  display: flex;
  width: 3px;
  height: 70px;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-left: none;
  background: $solidBlack;
  border-radius: 0;
  box-shadow: inset 0 0 0 1px $solidBlack, inset 0 0 0 17px #212121;

  &:active {
    width: 1px;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 40px;
    height: 100%;
    content: '';
  }
}

@keyframes arrowFade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

@mixin arrow {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-bottom: 1px solid $solidWhite;
  border-left: 1px solid $solidWhite;
  animation: arrowFade 2.6s infinite;
  // animation-delay: 4s;
  opacity: 0;
}

.helperArrows {
  @include arrow;

  &:first-child {
    transform: translate(8px, -50%) rotate(45deg);
  }

  &:nth-child(2) {
    animation-delay: 0.25s;
    transform: translate(18px, -50%) rotate(45deg);
  }

  &:last-child {
    animation-delay: 0.5s;
    transform: translate(28px, -50%) rotate(45deg);
  }
  // &::before,
  // &::after {
  //   @include arrow;

  //   animation: arrowFade 4s infinite;
  //   content: '';
  //   opacity: 1;
  // }

  // &::before {
  //   animation-delay: 0s;
  //   transform: translate(-8px, 2px);
  // }

  // &::after {
  //   animation-delay: 8s;
  //   transform: translate(6px, -12px);
  // }
}

.innerContainer {
  z-index: 1;
  width: 342px;
  height: 694px;
  padding: 15px 22px;
  background: $solidBlack;
  border-radius: 54px;
  box-shadow: inset 0 0 2px 2px #000, inset 0 0 0 6px #212121;
}

.header {
  position: absolute;
  z-index: 199;
  top: 15px;
  left: 50%;
  width: 164px;
  height: 20px;
  margin-left: -82px;
  background: $solidBlack;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.sensor {
  width: 40px;
  height: 4px;
  margin: 0 auto;
  margin-top: 5px;
  background: #151515;
  border-radius: 4px;
}

.logoContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: $solidWhite;
}

.logo {
  width: 180px;
  height: auto;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
