@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

.circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid $solidWhite;
  border-radius: 50%;
  transition: $infobarTransition;
}

.large {
  top: calc(100% - 12px);
  left: calc(100% - 12px);
  width: 150%;
  height: 150%;
  animation: wifiSignal 17s infinite;
}

.medium {
  top: calc(100% - 8px);
  left: calc(100% - 8px);
}

.small {
  top: calc(100% - 4px);
  left: calc(100% - 4px);
  width: 50%;
  height: 50%;
  background: $solidWhite;
}

.container {
  position: relative;
  top: -4px;
  overflow: hidden;
  width: 20px;
  height: 20px;
  margin-left: 1px;
  transform: rotate(45deg);

  &.invert {
    .circle {
      border-color: $solidBlack;
    }

    .large {
      animation-name: wifiSignalInverted;
    }

    .small {
      background: $solidBlack;
    }
  }
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
