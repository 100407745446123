@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.homeBtn {
  position: absolute;
  z-index: 110;
  top: calc(100% - 24px);
  right: 0;
  bottom: 0;
  left: 50%;
  width: 80%;
  height: 24px;
  animation: fadeIn 0.5s forwards 0.5s;
  background: transparent;
  opacity: 0;
  transform: translateX(-50%);

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 50%;
    width: 45%; // width: 60%;
    height: 4px;
    background: $solidWhite;
    border-radius: 4px;
    content: '';
    transform: translate(-50%, -50%);
  }
}

.invert {
  &::after {
    background: $solidBlack;
  }
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
