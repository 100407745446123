@import '../../sharedStyles/variables';

$number: #ff1204;

// ------------------
//  CONTENT
// ------------------

.container {
  z-index: 100;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-content: center;
  padding: 30px 15px 15px;
  background: $solidWhite;
  font-size: 12px;
  // text-align: center;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
