// ------------------
//  GENERAL
// ------------------

$white: #f0f0f0;
$black: #020202;
$solidWhite: #fff;
$solidBlack: #000;
$red: #f00;

$primaryColor: $black;
$secondaryColor: $white;

$bodyBG: $solidWhite;
$blue: #387ff7;

// ------------------
//  NAV
// ------------------

$navBG: $black;

// ------------------
//  NAV MOBILE
// ------------------

$mNavBG: $black;

// ------------------
//  FOOTER
// ------------------

$footerBG: $black;

// ------------------
//  SIGNAL
// ------------------

$noSignal: rgba($solidWhite, 0.35);
$noSignalInverted: rgba($solidBlack, 0.2);
