@import '../../sharedStyles/variables';

$number: #ff1204;
$accentBG: #f9f9f9;
$border: #c4c4c4;

// ------------------
//  CONTENT
// ------------------

.container {
  position: relative;
  width: 100%;
  height: 100%;
  background: $solidWhite;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.black {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $solidBlack;
}

.wrapper {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-content: center;
  background: $solidWhite;
  border-radius: 10px;
  text-align: center;
  transition: $dTransition;

  &.scaled {
    transform: scale(0.91);

    & .overlay {
      z-index: 5;
      opacity: 0.9;
    }
  }
}

.header,
.footer {
  z-index: 1;
  background: $accentBG;
}

.header {
  display: flex;
  height: 110px;
  max-height: 110px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 22px 12px;
  border-bottom: 1px solid $border;
  transition: $dTransition;
}

.headerBtns {
  position: absolute;
  top: 38px;
  text-align: left;
  transform: translateX(-14px);
}

.body {
  position: relative;
  flex-grow: 1;
  background: $solidWhite;
  transition: all 0.3s ease;
}

.footer {
  position: relative;
  display: flex;
  height: 64px;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  padding: 0 12px;
  border-top: 1px solid $border;
}

.status,
.unread {
  width: 100%;
  margin-bottom: 16px;
  font-size: 10px;
  letter-spacing: -0.4px;
}

.status {
  &Unread {
    margin-bottom: 0;
  }
}

.unread {
  color: #b1b1b5;
}

.opened {
  max-height: 68px;
}

.navigation {
  display: flex;
  flex-direction: column;
}

.backBtn,
.backArrow,
.mailboxes {
  background: none;
  text-align: left;
}

.backBtn,
.mailboxes {
  padding: 0;
}

.backBtn {
  border-radius: 0;
}

.backArrow {
  width: 14px;
  transition: $dTransition;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 6px;
    width: 2px;
    height: 12px;
    background: $blue;
    border-radius: 2px;
    content: '';
  }

  &::before {
    top: calc(50% - 3px);
    transform: translateY(-50%) rotate(45deg);
  }

  &::after {
    top: calc(50% + 4px);
    transform: translateY(-50%) rotate(135deg);
  }
}

.mailboxes {
  position: relative;
  padding: 0 0 0 18px;
  margin-right: 14px;
  margin-bottom: 20px;
  color: $blue;
  font-size: 14px;
  line-height: 12px;
  transform: translate(-18px, 0);
  transition: $dTransition;
}

.title {
  animation: inboxInactive 0.2s forwards;
  font-family: $primaryBold;
  font-size: 28px;
  line-height: 22px;
  transition: $dTransition;
}

.active {
  .header {
    max-height: 68px;
  }

  .title {
    animation: inboxActive 0.2s forwards;
    font-family: $primary;
    letter-spacing: -0.3px;
    line-height: 18px;
  }

  .mailboxes {
    display: none;
  }

  .body {
    background: #f7f5f7;
    transform: translateX(-25%);
  }
}

.filter {
  width: 18px;
  height: 100%;

  &Btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 18px;
    height: 18px;
    padding: 0;
    margin-top: 14px;
    background: none;
  }
}

.create {
  width: 18px;
  height: 100%;

  &Btn {
    position: relative;
    padding: 0;
    margin-top: 13px;
    background: none;
    border-radius: 0;
  }

  &Image {
    width: 19px;
    height: auto;
  }
}

.pencil {
  position: relative;
  display: block;
  width: 12px;
  height: 3px;
  border: 1px solid $blue;
  border-right: none;
  border-left: none;
  transform: rotate(-45deg) translateX(7px);

  &::before,
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
  }

  &::before {
    width: 0;
    height: 0;
    border-top: 1.5px solid transparent;
    border-right: 3px solid $blue;
    border-bottom: 1.5px solid transparent;
    transform: translate(-3px, -1px);
  }

  &::after {
    top: -1px;
    right: 0;
    left: 105%;
    width: 2px;
    height: 3px;
    background: $blue;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
  }
}

.flag {
  position: relative;
  width: 18px;
  height: 100%;

  &Btn {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 0;
    margin-top: 14px;
    background: none;
    transition: $dTransition;
  }

  &Image {
    width: 15px;
    height: auto;
  }
}

.filter {
  display: none;
  width: 18px;
  height: 100%;

  &Btn {
    position: relative;
    padding: 0;
    background: none;
    transition: $dTransition;
  }

  &Image {
    width: 20px;
    height: auto;
  }
}

.options {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
}

.reply {
  width: 20px;
  height: 100%;
  transition: $dTransition;

  &Btn {
    position: relative;
    display: flex;
    width: 22px;
    height: 16px;
    padding: 0;
    margin-top: 16px;
    background: none;
    border-radius: 0;
  }

  &Image {
    width: 20px;
    height: auto;
  }
}

.file {
  width: 18px;
  height: 100%;

  &Btn {
    position: relative;
    padding: 0;
    margin-top: 16px;
    background: none;
  }

  &Image {
    width: 18px;
    height: auto;
  }
}

.trash,
.file,
.filter,
.flag {
  opacity: 0;
  pointer-events: none;

  & img {
    display: none;
  }
}

.trash {
  width: 18px;
  height: 100%;

  &Btn {
    position: relative;
    padding: 0;
    margin-top: 12px;
    background: none;
  }

  &Image {
    width: 16px;
    height: auto;
  }
}

.hide {
  opacity: 0;
  pointer-events: none;
}

.overlay {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #c7c7c7;
  border-radius: 10px;
  opacity: 0.9;
  transition: $dTransition;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
