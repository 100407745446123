@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  // background: #7c56ff;
  // background: #002692;
  background: #2d21b7;
}

.innerContainer {
  display: flex;
  width: 100%;
  max-width: 1400px;
  height: auto;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
