@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

html,
body,
p,
a,
input,
select,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primaryColor;
  font-family: $primary;
  font-weight: 400;
}

a {
  transition: $dTransition;
}

html,
body {
  background: $bodyBG;
}

input,
textarea,
select {
  background: $solidWhite;
  font-family: $primary;
  font-size: 16px;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
