@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

.container {
  display: flex;
  width: 100%;
  height: 224px;
  flex-direction: row-reverse;
  align-items: flex-end;
  padding: 10px 12px;
  color: $solidWhite;
  font-family: $calc;
  font-size: 80px;
  text-align: right;
  word-break: break-all;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
