// ------------------
//  FONTS
// ------------------

$primary: 'SFProText-Regular';
$fontLight: 'SFCompactDisplay-Light';
$primaryBold: 'SFProDisplay-Bold';
$secondary: sans-serif;
$secondaryBold: sans-serif;
$calc: $fontLight;

// ------------------
//  BREAKPOINTS
// ------------------

$bpMD: 960px;
$bpSM: 680px;
$bpXS: 580px;
$bpMobile: 480px;

// ------------------
//  COLORS
// ------------------

@import 'style-guide/colors';

// ------------------
//  ANIMATIONS
// ------------------

@import 'style-guide/animations';

// ------------------
//  MISC
// ------------------

@import 'base/mixins';
