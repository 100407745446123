@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

.btn {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  font-size: 26px;
  transition: $btnTransition;

  &:nth-child(n + 5) {
    margin-top: 10px;
  }
}

.lightGrey {
  background: #a6a6a6;

  &:active {
    background: lighten(#a6a6a6, 10%);
  }
}

.orange {
  background: #f09937;
  color: $solidWhite;

  &:active {
    background: lighten(#f09937, 10%);
  }
}

.darkGrey {
  background: #333;
  color: $solidWhite;

  &:active {
    background: #868686;
  }
}

.zero {
  width: 136px;
  border-radius: 40px;
}

.active {
  background: $solidWhite;
  color: #f09937;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
