// ------------------
//  FONTS
// ------------------

// A nice font converter I use: https://onlinefontconverter.com/

@font-face {
  font-display: block;
  font-family: 'SFCompactDisplay-Light';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/SFCompactDisplay-Light.eot');
  src: url('/fonts/SFCompactDisplay-Light.woff2') format('woff2'),
    url('/fonts/SFCompactDisplay-Light.woff') format('woff'),
    url('/fonts/SFCompactDisplay-Light.ttf') format('truetype'),
    url('/fonts/SFCompactDisplay-Light.svg#SFCompactDisplay-Light') format('svg'),
    url('/fonts/SFCompactDisplay-Light.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-display: block;
  font-family: 'SFProText-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/SFProText-Regular.eot');
  src: url('/fonts/SFProText-Regular.woff2') format('woff2'), url('/fonts/SFProText-Regular.woff') format('woff'),
    url('/fonts/SFProText-Regular.ttf') format('truetype'),
    url('/fonts/SFProText-Regular.svg#SFProText-Regular') format('svg'),
    url('/fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-display: block;
  font-family: 'SFProDisplay-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/SFProDisplay-Bold.eot');
  src: url('/fonts/SFProDisplay-Bold.woff2') format('woff2'), url('/fonts/SFProDisplay-Bold.woff') format('woff'),
    url('/fonts/SFProDisplay-Bold.ttf') format('truetype'),
    url('/fonts/SFProDisplay-Bold.svg#SFProDisplay-Bold') format('svg'),
    url('/fonts/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype');
}
