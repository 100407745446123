@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

.icon {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 52px;
  height: 52px;
  min-height: 52px;
  background-color: #d0d8df;
  border-radius: 14px;
  transition: all 0.3s ease-in-out;
}

.image {
  width: auto;
  max-width: 100%;
}

.name {
  margin-top: 3px;
  color: $solidWhite;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
}

.hidden {
  z-index: 11;
  // opacity: 0;
}

.gone {
  z-index: -1;
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $solidBlack;
  opacity: 0.75;
}

.pie {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  content: '';
  transform: translate(-50%, -50%);

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 50%;
    width: 32px;
    height: 32px;
    content: '';
  }

  &::before {
    right: -50%;
    background: $solidWhite;
    transform: rotate(-140deg) skewY(50deg);
    transform-origin: top left;
  }

  &::after {
    position: absolute;
    top: 50%;
    border: 2px solid $solidWhite;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
