@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

.container {
  z-index: 100;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-content: center;
  padding: 30px 0 15px;
  padding-top: 80px;
  background: $solidWhite;
  background-position: center center;
  background-size: contain;
  font-size: 12px;
}

.title {
  padding: 0 10px;
  margin-left: 12px;
}

.playBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 22px;
}

.tracks {
  max-height: 400px;
  flex-grow: 1;
  padding: 0 10px;
  margin-top: 10px;
  overflow-y: auto;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
