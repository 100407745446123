@import '../../../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

.bar {
  width: 8%;
  height: 100%;
  background: transparent;
  transition: width 10s ease;
}

.loading {
  width: 92%;
  background: $blue;
}

.done {
  width: 100%;
  transition: width 0.1s ease;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
