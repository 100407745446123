@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

.container {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: $solidWhite;
  opacity: 0;
}

.animate {
  animation: applelogo 3s forwards;
}

.logo {
  width: 180px;
  height: auto;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
