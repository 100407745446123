@import '../../../../sharedStyles/variables';

$bg: #f8f8fc;
$color: #fe2c55;

// ------------------
//  CONTENT
// ------------------

.btn {
  width: 100%;
  max-width: 138px;
  height: auto;
  min-height: 38px;
  background-color: $bg;
  border-radius: 8px;
  color: $color;
  font-family: $primaryBold;
  font-size: 14px;
  transition: $dTransition;

  &:hover {
    background-color: #f3f3f3;
  }
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
