@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

$battery: #eaeaea;
$charging: #53d86a;
$lowBattery: #f83f42;

.container {
  position: relative;
  width: 18px;
  height: 9px;
  border: 1px solid $battery;
  margin-left: 1px;
  border-radius: 2px;

  &,
  &::after {
    transition: $infobarTransition;
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: calc(100% + 2px);
    width: 1px;
    height: 3px;
    background: $battery;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    content: '';
    transform: translateY(-50%);
  }
}

.level {
  width: 55%;
  min-width: 1px;
  max-width: calc(100% - 2px);
  height: calc(100% - 2px);
  margin: 1px 0 0 1px;
  background: $solidWhite;
  border-radius: 1px;
  transition: $infobarTransition;
}

.inverted {
  border-color: $black;

  &::after {
    background: $black;
  }

  & .level {
    background: $solidBlack;
  }
}

.charging .level {
  background: $charging;
}

.lowBattery {
  background: $lowBattery;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
