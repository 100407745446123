@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

.container {
  position: relative;
  display: flex;
  width: 52px;
  min-height: 52px;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  border-radius: 14px;

  &:nth-of-type(n + 5) {
    margin-top: 20px;
  }
}

.appContainer {
  position: absolute;
  z-index: 105;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 52px;
  height: 52px;
  border-radius: 10px;
  transform: scale(1);
  transition: all 0.3s ease-in-out;

  &:first-child {
    z-index: 10;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.homeBtn {
  position: absolute;
  z-index: 110;
  top: calc(100% - 24px);
  right: 0;
  bottom: 0;
  left: 50%;
  width: 80%;
  height: 24px;
  animation: fadeIn 0.5s forwards 0.5s;
  background: transparent;
  opacity: 0;
  transform: translateX(-50%);

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 50%;
    width: 45%; // width: 60%;
    height: 4px;
    background: $solidWhite;
    border-radius: 4px;
    content: '';
    transform: translate(-50%, -50%);
  }
}

.invert {
  &::after {
    background: $solidBlack;
  }
}

.inactive {
  z-index: 100;
}

.hide {
  top: 50%;
  left: 50%;
  overflow: hidden;
  border-radius: 14px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(0.01);
}

.hidden {
  opacity: 0;
}

.scaled {
  transform: scale(0);
}

.appWrapper {
  display: flex;
  overflow: auto;
}

.notification {
  position: absolute;
  z-index: 12;
  top: -8px;
  right: -8px;
  bottom: 0;
  left: 0;
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background: $red;
  border-radius: 50%;
  color: $solidWhite;
  font-size: 12px;
  transition: $dTransition;

  &Hide {
    transform: scale(0);
  }
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
