@import '../../sharedStyles/variables';

$number: #ff1204;

// ------------------
//  CONTENT
// ------------------

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  background: $solidWhite;
}

.day,
.number {
  margin: 0 auto;
}

.day {
  color: $number;
  font-size: 9px;
}

.number {
  font-family: $fontLight;
  font-size: 32px;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
