@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

.container {
  position: relative;
  z-index: 1;
  display: flex;
  width: 342px;
  height: 694px;
  background: $solidBlack;
  border-radius: 54px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.75);
}

.innerContainer {
  z-index: 1;
  width: 342px;
  height: 694px;
  padding: 15px 22px;
  background: $solidBlack;
  border-radius: 54px;
  box-shadow: inset 0 0 2px 2px #000, inset 0 0 0 6px #212121;
}

.header {
  position: absolute;
  z-index: 199;
  top: 15px;
  left: 50%;
  width: 164px;
  height: 20px;
  margin-left: -82px;
  background: $solidBlack;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.sensor {
  width: 40px;
  height: 4px;
  margin: 0 auto;
  margin-top: 5px;
  background: #151515;
  border-radius: 4px;
}

.screen {
  position: relative;
  width: 312px;
  height: 662px;
  padding-top: 20px;
  margin-left: -8px;
  background-color: #101010;
  background-position: center center;
  background-size: cover;
  border-radius: 32px;
}

.keyPad {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 12px;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
