@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

.time,
.info {
  display: flex;
  width: 70px;
  height: 20px;
  align-items: flex-end;
}

.time {
  justify-content: center;
  color: $solidWhite;
  font-family: $primary;
  font-size: 12px;
  line-height: 13px;
  text-align: center;
}

.invert {
  color: $solidBlack;
}
