@import '../../sharedStyles/variables';

// ------------------
//  CONTENT
// ------------------

.screen {
  position: relative;
  z-index: 1;
  display: flex;
  overflow: hidden;
  width: 312px;
  height: 662px;
  flex-direction: column;
  margin-left: -8px;
  background-color: #101010;
  border-radius: 32px;
}

.background {
  position: absolute;
  z-index: -1;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  width: auto;
  height: 100%;
  opacity: 0.8;
  transform: translate(-50%, -50%);
  transition: $dTransition;

  &.off {
    opacity: 0;
  }
}

.off {
  &::after {
    opacity: 0;
  }
}

.screenContainer {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  padding: 32px 12px;
}

.dock {
  display: flex;
  width: calc(100% - 18px);
  align-items: center;
  justify-content: center;
  padding: 13px 0;
  margin: 0 auto 2px;
  background: rgba(83, 96, 109, 0.9);
  border-radius: 24px;
}

.hide {
  display: none;
}

// ------------------
//  BREAKPOINTS
// ------------------

@media screen and (max-width: $bpMD) {
}

@media screen and (max-width: $bpSM) {
}

@media screen and (max-width: $bpXS) {
}

@media screen and (max-width: $bpMobile) {
}
